body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  font-family: "Roboto", Helvetica, sans-serif;
  overscroll-behavior: none;
  overflow: auto;
  font-weight: 800;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--bg-newBlue);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Inter-Bold";
  src:
    local("Inter-Bold"),
    url(./font/Inter-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Inter-Regular";
  src:
    local("Inter-Regular"),
    url(./font/Inter-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Inter-Medium";
  src:
    local("Inter-Medium"),
    url(./font/Inter-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "CoreMellow-Bold";
  src:
    local("CoreMellow-Bold"),
    url(./font/Core-Mellow-65-Bold.otf) format("truetype");
}

:root {
  --bg-black-90: #0e0c15;
  --color-green: #4caf50;
  --color-blue: #42a4f5;
  --color-purple: #9c27b0;
  --bg-red: #ce0033;
  --bg-purple-10: #990099;
  --bg-blue: #192ee9;
  --bg-newBlue: #080b29;
}
