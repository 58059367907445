.social-media-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #000; /* Make sure this is the desired background color for the page */
}

.logo {
  display: flex;
  max-width: 5%;
}

.tagline {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  color: white;
  font-weight: 400;
  margin-bottom: 5%;
}

.open-in-new-tab-button {
  display: flex;
  width: 30vw;
  height: 4vh;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  color: white;
  background: linear-gradient(270deg, #9c27b0, #2196f3);
  background-size: 200% 200%;
  border: none;
  cursor: pointer;
  animation: gradientShift 3s ease infinite;
  outline: none; /* Removes the focus outline */
}

.open-in-new-tab-button i {
  margin-left: 0.5rem;
}

/* Keyframes for lava lamp-like background movement */
@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
